import { $, $$ } from '../helpers/query-selector';
import onResize from '../helpers/on-resize';
import { reducedMotion, viewport } from '../helpers/variables';
import { gsap, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

const dropdowns = $$('.js-dropdown');
const main = $('.js-main');
const navLang = $('.js-nav-lang');

const flyoutActiveClass = 'l-nav__flyout--active';
const langNavActiveClass = 'l-nav__lang--visible';
const overlayClass = 'l-nav__overlay';

function showDropdownNav(dropdown) {
  const toggle = $('.js-dropdown-toggle', dropdown);
  const flyout = $('.js-dropdown-flyout', dropdown);

  toggle.setAttribute('aria-expanded', 'true');
  flyout.classList.add(flyoutActiveClass);
  navLang.classList.add(langNavActiveClass);
  main.classList.add(overlayClass);
}

function hideDropdownNav(dropdown) {
  const toggle = $('.js-dropdown-toggle', dropdown);
  const flyout = $('.js-dropdown-flyout', dropdown);

  toggle.setAttribute('aria-expanded', 'false');
  flyout.classList.remove(flyoutActiveClass);
  navLang.classList.remove(langNavActiveClass);
  main.classList.remove(overlayClass);
}

function scrollToAnchor(dropdown, anchors) {
  for (const anchor of anchors) {
    anchor.addEventListener('click', e => {
      e.preventDefault();

      hideDropdownNav(dropdown);

      const url = new URL(anchor.href);
      const target = $(url.hash);

      gsap.to(window, {
        duration: reducedMotion.matches ? 0 : 1,
        scrollTo: { y: target, offsetY: 111, autoKill: true }, // offset = header height
        ease: 'power2.inOut',
        onComplete: () => {
          // Move focus
          target.setAttribute('tabindex', '-1');
          target.classList.add('focus-hidden');
          target.focus({ preventScroll: true });
          target.removeAttribute('tabindex');

          // Update history
          history.pushState(null, null, url);
        }
      });
    });
  }
}

function initAnchors(dropdown) {
  const partnerAnchors = $$('.js-partner-anchor', dropdown);
  const navAnchors = $$('.js-nav-anchor', dropdown);

  if (partnerAnchors.length > 0) {
    scrollToAnchor(dropdown, partnerAnchors);
  }

  if (navAnchors.length > 0) {
    scrollToAnchor(dropdown, navAnchors);
  }
}

function initDropdownNav() {
  if (!dropdowns) return;

  for (const dropdown of dropdowns) {
    const toggle = $('.js-dropdown-toggle', dropdown);

    toggle.addEventListener('click', () => {
      if (toggle.getAttribute('aria-expanded') == 'true') {
        hideDropdownNav(dropdown);
      } else {
        for (const dropdown of dropdowns) {
          hideDropdownNav(dropdown);
        }
        showDropdownNav(dropdown);
      }
    });

    initAnchors(dropdown);
  }

  // Close dropdown on resize
  onResize(
    () => {
      for (const dropdown of dropdowns) {
        hideDropdownNav(dropdown);
      }
    },
    { horizontalOnly: true }
  );

  // Close dropdowns on main click
  $('.js-main').addEventListener('click', () => {
    for (const dropdown of dropdowns) {
      hideDropdownNav(dropdown);
    }
  });
}

export default initDropdownNav;
