import '../scss/app.scss'; // Vite requires css import in app.js

import initLazyLoad from './modules/lazyload';
import initDropdownNav from './modules/dropdown-nav';
import { $ } from './helpers/query-selector';

async function init() {
  const polyfills = [];

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import('intersection-observer'));
  }

  await Promise.all(polyfills);

  initLazyLoad();
  initDropdownNav();

  if ($('.js-search')) import('./modules/search').then(m => m.default());

  if ($('.js-partner-list'))
    import('./modules/partner-list').then(m => m.default());

  if ($('.js-timeline')) import('./modules/timeline').then(m => m.default());

  if ($('.js-toggle')) import('./modules/toggle').then(m => m.default());

  if ($('.js-event-teaser'))
    import('./modules/event-teaser').then(m => m.default());

  if ($('.js-event-filter'))
    import('./modules/event-filter').then(m => m.default());

  if ($('.js-engagement-teaser'))
    import('./modules/engagement-teaser').then(m => m.default());

  if ($('.js-form')) import('./modules/form').then(m => m.default());

  if ($('.js-newsletter'))
    import('./modules/newsletter').then(m => m.default());

  if ($('.js-survey')) import('./modules/survey').then(m => m.default());

  if ($('.js-dialog')) import('./modules/dialog').then(m => m.default());

  if ($('.js-slideshow')) import('./modules/slideshow').then(m => m.default());

  if ($('.js-iframe')) import('./modules/iframe').then(m => m.default());

  if ($('.js-aside-nav')) import('./modules/aside-nav').then(m => m.default());
}

init();
